export default {
  colors: {
    text: `hsla(0,0%,0%,0.9)`,
    background: `#fff`,
    primary: `#1F7993`, //
    secondary: `#6CC417`,
    muted: `silver`,
    accent: `secondary`,
    gray: [`gray`, `darkgray`, `silver`, `lightgray`, `gainsboro`, `whitesmoke`],
  },
  fonts: {
    body: `Didact+Gothic, system-ui, sans-serif`,
    heading: `Nunito+Sans, system-ui, sans-serif`,
    monospace: `Menlo, monospace`,
  },
  fontWeights: {
    body: 300,
    heading: 500,
    bold: 500,
  },
  /* eslint-disable-next-line max-len */
  //fontSizes: [`0.825rem`, `1rem`, `1.125rem`, `1.25rem`, `1.5rem`, `2rem`, `2.5rem`, `3.5rem`, `4rem`, `6rem`],
  fontSizes: [14, 16, 18, 20, 24, 32, 48, 64, 72, 96],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [600, 900, 1200, 1800].map(n => n + `px`),
  sizes: {
    container: {
      fullscreen: `100%`,
      large: `calc(1200px + 20vw)`,
      smallplus: `calc(960px + 10vw)`,
      default: `960px`,
    },
  },
  buttons: {
    color: `text`,
    borderColor: `text`,
    border: `2px solid`,
    bg: `background`,
    px: 4,
    py: 2,
    fontWeight: `heading`,
    '&:hover': {
      color: `background`,
      bg: `text`,
      transition: `0.5 ease-out all`,
    },
    large: {
      border: `3px solid`,
      px: 6,
      py: 3,
      fontSize: 5,
      fontWeight: `heading`,
    },
    small: {
      border: `2px solid`,
      borderColor: `text`,
      color: `text`,
      bg: `transparent`,
      textDecoration: `none`,
      px: 2,
      py: 1,
      fontSize: 2,
      fontWeight: `text`,
      ':hover': {
        color: `background`,
        bg: `text`,
      },
    },
  },
  styles: {
    h1: {
      fontWeight: 700,
      fontSize: [3, 5, 6],
    },
    display: {
      fontWeight: 700,
      fontSize: [6, 7, 8],
    },
    displayLight: {
      fontWeight: 700,
      fontSize: [5, 6, 7],
    },
    a: {
      fontWeight: `400`,
      textDecoration: `none`,
      color: `primary`,
      ':hover': {
        textDecoration: `underline`,
      },
      ':visited': {
        color: `primary`,
      },
    },
    navlink: {
      ml: 3,
      pt: 1,
      pb: 2,
      fontWeight: 400,
      textDecoration: `none`,
      color: `text`,
      ':hover': {
        color: `primary`,
        transition: `1s ease-in all`,
      },
      '&.active': {
        color: `primary`,
      },
    },
    burgerlink: {
      ml: 3,
      pt: 1,
      pb: 2,
      fontWeight: 400,
      fontSize: 5,
      textDecoration: `none`,
      color: `background`,
      ':hover': {
        //color: `secondary`,
        textDecoration: `underline`,
        transition: `1s ease-in all`,
      },
      '&.active': {
        //color: `secondary`,
        textDecoration: `underline`,
      },
    },
    footerLink: {
      mx: [`auto`, 0],
      ml: [`auto`, 3],
      py: [1, 1, 2],
      fontWeight: 300,
      fontSize: [1, 1, 2],
      textDecoration: `none`,
      color: `text`,
      ':hover': {
        color: `primary`,
        transition: `1s ease-in all`,
      },
      '&.active': {
        color: `primary`,
      },
    },
    footerText: {
      mx: [`auto`, 0],
      ml: [`auto`, 2, 2],
      py: [1, 1, 2],
      fontSize: [1, 1, 2],
      fontWeight: 300,
      color: `text`,
    },
    reference: {
      tile: {
        height: [240, 320, 320, 400],
      },
      image: {
        height: [212, 288, 284, 364],
      },
      tagline: {
        width: `100%`,
        height: [28, 32, 36],
        m: 0,
        px: [1, 2, 3],
        pt: [1, 1, 2],
        fontWeight: 400,
        fontSize: [3, 3, 4],
        lineHeight: 1,
      },
    },
  },
}
